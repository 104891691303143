export default [
  //JASA
  {
    path: '/jasa',
    name: 'route-jasa',
    component: () => import('@/views/pages/jasa/List.vue'),
    meta: {
      module: 'Jasa',
      pageTitle: 'List Jasa',
      authRequired: true,
    },
  },
  {
    path: '/jasa/add',
    name: 'route-jasa-add',
    component: () => import('@/views/pages/jasa/Store.vue'),
    meta: {
      parent: '/jasa',
      module: 'Jasa',
      authRequired: true,
      pageTitle: 'Tambah Jasa',
      breadcrumb: [
        {
          text: 'List',
          to: '/jasa',
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/jasa/edit/:id',
    name: 'route-jasa-edit',
    component: () => import('@/views/pages/jasa/Edit.vue'),
    meta: {
      parent: '/jasa',
      module: 'Jasa',
      authRequired: true,
      pageTitle: 'Edit Jasa',
      breadcrumb: [
        {
          text: 'List',
          to: '/jasa',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]

export default [
  {
    path: '/barang',
    name: 'route-barang',
    component: () => import('@/views/pages/barang/List.vue'),
    meta: {
      module: 'Barang',
      pageTitle: 'List Barang',
      authRequired: true,
    },
  },
  {
    path: '/barang/add',
    name: 'route-barang-add',
    component: () => import('@/views/pages/barang/Store.vue'),
    meta: {
      parent: '/barang',
      module: 'Barang',
      authRequired: true,
      pageTitle: 'Tambah Barang',
      breadcrumb: [
        {
          text: 'Barang',
          to: '/barang',
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/barang/edit/:id',
    name: 'route-barang-edit',
    component: () => import('@/views/pages/barang/Edit.vue'),
    meta: {
      parent: '/barang',
      module: 'Barang',
      authRequired: true,
      pageTitle: 'Edit Barang',
      breadcrumb: [
        {
          text: 'Barang',
          to: '/barang',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
